import React, { useState } from "react"
import { Link } from "gatsby"
import { Transition } from "@headlessui/react"

const Navbar = ({ location }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <React.Fragment>
      <nav className="font-nunito navbar shadow-lg text-neutral-content w-100 dark:bg-slate-900 drop-shadow-sm">
        <div className="px-2 mx-2 w-10/12 md:navbar-start">
          <span className="font-damion text-3xl font-bold text-zinc-700 dark:text-sky-200 hover:dark:text-zinc-400 hover:text-zinc-900 hover:cursor-default">
            Jason Fritsche
          </span>
        </div>

        <div className="navbar-end">
          <div className="items-stretch md:w-3/4 lg:w-3/5 lg:pr-2 hidden md:flex flex-row justify-end">
            <Link
              to="/"
              className={
                location.pathname === "/"
                  ? "font-nunito text-sky-700 dark:text-sky-200 text-xl font-bold pr-2"
                  : "block font-nunito text-xl font-bold text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400 pr-2"
              }
            >
              Home
            </Link>
            <Link
              to="/blog"
              className={
                location.pathname === "/blog"
                  ? "font-nunito text-sky-700 dark:text-sky-200 text-xl font-bold pr-2"
                  : "block font-nunito text-xl font-bold text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400 pr-2"
              }
            >
              Blog
            </Link>
            <Link
              to="/portfolio"
              className={
                location.pathname === "/portfolio"
                  ? "font-nunito text-sky-700 dark:text-sky-200 text-xl font-bold pr-2"
                  : "block font-nunito text-xl font-bold text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400 pr-2"
              }
            >
              Portfolio
            </Link>
            <Link
              to="/contact"
              className={
                location.pathname === "/contact"
                  ? "font-nunito text-sky-700 dark:text-sky-200 text-xl font-bold pr-2"
                  : "block font-nunito text-xl font-bold text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400 pr-2"
              }
            >
              Contact
            </Link>
          </div>
          <div className="flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </nav>
      <Transition
        show={isOpen}
        enter="transition ease-in duration-200 transform"
        enterFrom="opacity-70 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-out duration-950 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="absolute z-10 w-full bg-white dark:bg-slate-900"
      >
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col items-end">
            <Link
              to="/"
              className={
                location.pathname === "/"
                  ? "border-b-2 font-nunito border-zinc-800 text-zinc-700 dark:border-zinc-400 dark:text-zinc-100 text-xl font-bold"
                  : "block font-nunito text-xl font-bold text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400"
              }
              onClick={() => setIsOpen(true)}
            >
              Home
            </Link>
            <Link
              to="/blog"
              className={
                location.pathname === "/blog"
                  ? "border-b-2 font-nunito border-zinc-800 text-zinc-700 dark:border-zinc-400 dark:text-zinc-100 text-xl font-bold"
                  : "block font-nunito text-xl font-bold text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400"
              }
            >
              Blog
            </Link>
            <Link
              to="/portfolio"
              className={
                location.pathname === "/portfolio"
                  ? "border-b-2 font-nunito border-zinc-800 text-zinc-700 dark:border-zinc-400 dark:text-zinc-100 text-xl font-bold"
                  : "block font-nunito text-xl font-bold text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400"
              }
            >
              Portfolio
            </Link>
            <Link
              to="/contact"
              className={
                location.pathname === "/contact"
                  ? "border-b-2 font-nunito border-zinc-800 text-zinc-700 dark:border-zinc-400 dark:text-zinc-100 text-xl font-bold"
                  : "block font-nunito text-xl font-bold text-zinc-700 hover:text-zinc-900 dark:text-zinc-100 dark:hover:text-zinc-400"
              }
            >
              Contact
            </Link>
          </div>
        </div>
      </Transition>
    </React.Fragment>
  )
}

export default Navbar
