import * as React from "react"
import Navbar from "./navbar"
import Footer from "./footer"

const Layout = ({ location, title, children }) => {
  console.log("location", location)
  const rootPath = `${__PATH_PREFIX__}/`
  console.log("rootPath", rootPath)
  const isRootPath = location.pathname === rootPath
  console.log("isRootPath", isRootPath)
  return (
    <div
      className={`min-h-screen flex flex-col relative ${
        isRootPath
          ? "bg-pink-200 dark:bg-gradient-to-r from-slate-400 via-slate-400 to-slate-700"
          : "bg-white dark:bg-zinc-400"
      }`}
    >
      <Navbar location={location} />
      <main
        data-is-root-path={isRootPath}
        className={`pb-24 ${
          isRootPath
            ? "bg-pink-200 dark:bg-gradient-to-r from-slate-400 via-slate-400 to-slate-700"
            : "bg-white dark:bg-zinc-400"
        }`}
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
