import React from "react"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaDev } from "@react-icons/all-files/fa/FaDev"

const Footer = () => {
  return (
    <div className="absolute bottom-0 drop-shadow-md dark:bg-zinc-400 h-auto p-2 w-full">
      <div className="flex flex-row justify-center h-full items-center">
        <a
          href="https://github.com/JasonFritsche"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 text-3xl text-stone-700 dark:text-stone-800 hover:dark:text-stone-700 hover:cursor-pointer"
        >
          <FaGithub />
        </a>
        <a
          href="https://www.linkedin.com/in/jason-fritsche/"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 text-3xl text-stone-700 dark:text-stone-800 hover:dark:text-stone-700 hover:cursor-pointer"
        >
          <FaLinkedin />
        </a>
        <a
          href="https://dev.to/juniordevforlife"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 text-3xl text-stone-700 dark:text-stone-800 hover:dark:text-stone-700 hover:cursor-pointer"
        >
          <FaDev />
        </a>
      </div>
      <div className="flex flex-row justify-center">
        <p className="text-lg">
          <span className="font-nunito dark:text-slate-900">
            Designed and built by{" "}
          </span>
          <span className="font-damion text-2xl dark:text-sky-700">
            Jason Fritsche
          </span>
        </p>
      </div>
    </div>
  )
}

export default Footer
